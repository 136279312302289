<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="290"
    >
      
      <v-card>
        <v-card-title class="text-h5">
        
        </v-card-title>
        <v-card-text><div class="text-h6 text-center"> You are in Floor {{ currentFloor }}</div></v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {

  },
  props: ["currentFloor",],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  data: () => ({
    showDialog: true,
   
  }),
 
  mounted() {
   
  },
  methods:{
  
  }
};
</script>
<style>
.spent-ladder {
  width: 50px;
}
</style>
